import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'renameMaticToPol',
})
export class RenameMaticToPolPipe implements PipeTransform {
  transform(text: string): string {
    if (!text) {
      return ''; // Return an empty string if the input is null or undefined
    }
    // Replace specific exact matches first
    switch (text) {
      case 'MATIC':
        return 'POL';
      case 'matic':
        return 'POL';
      case 'WMATIC':
        return 'WPOL';
      default:
        // Replace all occurrences of "Matic" with "POL" in the text (case-insensitive)
        return text.replace(/Matic/gi, 'POL');
    }
  }
}
